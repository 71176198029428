import configStore, { Lang } from '../stores/configStore';

export interface Translation {
    [key: string | Lang]: Translation | string;
}

export function t2(key: string) {
    const lang = configStore.lang;
    const keys = key.split('.');
    let obj = (content as Translation)[keys[0]];
    if (obj) {
        for (let i = 1; i < keys.length; i++) {
            obj = (obj as Translation)[keys[i]];
            if (!obj) break;
        }
    }

    if (obj) return (obj as Translation)[lang];
}

export function t(obj: any) {
    const lang = configStore.lang;
    return obj[lang];
}

export const content = {
    system: {
        defaultGroupName: { ru: 'Личная', en: 'Personal' },
        defaultTaskGroupName: { ru: 'Основной', en: 'Main' }
    }
};
