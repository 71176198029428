import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import modalStore from '../stores/modalStore';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const TaskGroupEdit = observer(() => {
    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    return (
        <>
            <Box mt={2}>
                <TextField
                    inputRef={ref}
                    name="name"
                    label="Список"
                    value={modalStore.data.name ?? ''}
                    placeholder="Введите название нового списка"
                    required
                    autoFocus={true}
                    fullWidth
                />
            </Box>
            <Box mt={2}>
                <TextField
                    name="description"
                    label="Описание"
                    value={modalStore.data.description ?? ''}
                    multiline
                    rows={4}
                    fullWidth
                />
            </Box>
        </>
    );
});

export default TaskGroupEdit;
