import { useState, useEffect, useCallback } from 'react';

export type screenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type sizeDep = { name: screenSize; width: number };

const Sizes: sizeDep[] = [
    { name: 'xs', width: 600 },
    { name: 'sm', width: 900 },
    { name: 'md', width: 1200 },
    { name: 'lg', width: 1536 },
    { name: 'xl', width: 1000000 }
];

function getSize(w: number): screenSize {
    for (let i = 0; i < Sizes.length; i++) {
        if (w < Sizes[i].width) return Sizes[i].name;
    }
    return 'xl';
}

export const useSize = () => {
    const [size, setSize] = useState<screenSize>(() => getSize(window?.innerWidth || 0));

    const resizeHandler = useCallback(() => {
        setSize(getSize(window.innerWidth || 0));
    }, []);

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, [resizeHandler]);

    return size;
};
