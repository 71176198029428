// @flow
import React, { useCallback, useEffect, useRef } from 'react';

interface TelegramLoginButtonProps {
    botName?: string;
    dataOnauth: any;
    buttonSize?: 'large' | 'medium' | 'small';
    requestAccess?: string;
    lang?: string;
    widgetVersion?: number;
    dataAuthUrl?: string;
    className?: string;
    children?: React.ReactElement;
}

interface TelegramLoginWidget {
    dataOnauth: (user: Record<string, any>) => void;
}

declare global {
    interface Window {
        TelegramLoginWidget: TelegramLoginWidget;
    }
}

const TelegramLoginButton = ({
    botName = 'TodationBot',
    buttonSize = 'large',
    requestAccess = 'write',
    dataOnauth = () => undefined,
    dataAuthUrl,
    lang = 'en',
    widgetVersion = 22,
    className,
    children
}: TelegramLoginButtonProps) => {
    const instanceRef = useRef<HTMLDivElement>(null);

    const createScript = useCallback(() => {
        /* <script 
            async src="https://telegram.org/js/telegram-widget.js?22" 
            data-telegram-login="TodationBot" 
            data-size="large" 
            data-onauth="onTelegramAuth(user)" 
            data-request-access="write">
        </script>
        <script type="text/javascript">
        function onTelegramAuth(user) {
            alert('Logged in as ' + user.first_name + ' ' + user.last_name + ' (' + user.id + (user.username ? ', @' + user.username : '') + ')');
        }
        </script> */

        const script = document.createElement('script');
        script.src = `https://telegram.org/js/telegram-widget.js?${widgetVersion}`;
        script.setAttribute('data-telegram-login', botName);
        script.setAttribute('data-size', buttonSize);
        script.setAttribute('data-request-access', requestAccess);
        script.setAttribute('data-lang', lang);
        if (dataAuthUrl !== undefined) {
            script.setAttribute('data-auth-url', dataAuthUrl);
        } else {
            script.setAttribute('data-onauth', 'TelegramLoginWidget.dataOnauth(user)');
        }
        script.async = true;

        return script;
    }, [widgetVersion, botName, buttonSize, requestAccess, lang, dataAuthUrl]);

    useEffect(() => {
        window.TelegramLoginWidget = {
            dataOnauth: (user: Record<string, any>) => dataOnauth(user)
        };

        const script = createScript();

        const ref = instanceRef?.current;

        if (ref) ref.appendChild(script);

        return () => {
            if (ref) ref.removeChild(script);
        };
    }, [createScript, dataOnauth]);

    return (
        <div style={{ margin: '0 auto' }} className={className} ref={instanceRef}>
            {children}
        </div>
    );
};

export default TelegramLoginButton;
