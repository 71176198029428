import CheckIcon from '@mui/icons-material/Check';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CloseIcon from '@mui/icons-material/Close';

export type Group = {
    group_guid: string;
    name: string;
    is_personal: boolean;
};

export type TaskGroup = {
    task_group_guid: string;
    group_guid: string;
    name: string;
    description: string;
    is_default: boolean;
    sort_order: number;
};

export type Task = {
    task_guid: string;
    task_group_guid: string;
    task_number: string;
    name: string;
    description: string;
    status: string;
    completed_at: Date;
    creator_guid: string;
    executor_guid: string;
};

export type TaskStatus = 'W' | 'A' | 'F' | 'C';
// W - wait, A - active, F - finished, C - cancelled

export type TaskStatusDescr = {
    status: TaskStatus;
    action: string;
    label: string;
    multiLabel: string;
    icon: typeof CheckIcon;
    color: string;
};

export const arrTaskStatus: TaskStatusDescr[] = [
    {
        status: 'A',
        action: 'Запустить',
        label: 'Активна',
        multiLabel: 'Активные',
        icon: PlayArrowIcon,
        color: 'blue'
    },
    {
        status: 'F',
        action: 'Завершить',
        label: 'Завершена',
        multiLabel: 'Завершенные',
        icon: CheckIcon,
        color: 'green'
    },
    {
        status: 'W',
        action: 'Ожидать',
        label: 'В ожидании',
        multiLabel: 'В ожидании',
        icon: HourglassTopIcon,
        color: 'darkgrey'
    },
    {
        status: 'C',
        action: 'Отменить',
        label: 'Отменена',
        multiLabel: 'Отмененые',
        icon: CloseIcon,
        color: 'red'
    }
];

export type FilterStatus = Record<TaskStatus, boolean>;
