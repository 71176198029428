import React from 'react';
import { observer } from 'mobx-react-lite';

const AdminPage = observer(() => (
    <div>
        <h2>Administrator</h2>
    </div>
));

export default AdminPage;
