import * as React from 'react';
import { observer } from 'mobx-react-lite';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material/Select';

import dataStore from '../stores/dataStore';
import { content, t } from '../translate';

const MAIN_WORK_GROUP_NAME = '%MAIN%';

const WorkGroupSelect = observer(() => {
    const handleChange = (event: SelectChangeEvent) => {
        dataStore.assignActiveWorkGroupGuid(event.target.value);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
                value={dataStore.activeWorkGroupGuid ?? ''}
                onChange={handleChange}
                style={{ backgroundColor: 'white' }}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                {dataStore.workGroups.map(workGroup => (
                    <MenuItem key={workGroup.group_guid} value={workGroup.group_guid}>
                        {workGroup.name === MAIN_WORK_GROUP_NAME
                            ? t(content.system.defaultGroupName)
                            : workGroup.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});

export default WorkGroupSelect;
