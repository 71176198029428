// LoginForm.js
import React, { useEffect } from 'react';
import { Navigate } from 'react-router';
import configStore from '../stores/configStore';
import { observer } from 'mobx-react-lite';

const LogoutPage = observer(() => {
    const { logout } = configStore;

    useEffect(() => {
        logout();
    }, [logout]);

    return <Navigate to="/login" />;
});

export default LogoutPage;
