import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import configStore from './stores/configStore';
import LoginPage from './pages/login';
import LogoutPage from './pages/logout';
import AnonymePage from './pages/anonyme';
import HomePage from './pages/home';
import AdminPage from './pages/admin';
import Main from './components/Main';
import TopBar from './components/TopBar';
import ModalContainer from './components/ModalContainer';

const ProtectedRoute: React.FC<{ element: React.ReactNode }> = observer(({ element }) => {
    const { isAuthenticated, anonymeSession } = configStore;
    return isAuthenticated ? (
        anonymeSession ? (
            <Navigate to="/anonyme" />
        ) : (
            (element as React.ReactElement)
        )
    ) : (
        <Navigate to="/login" />
    );
});

const RoutesBlock: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<ProtectedRoute element={<HomePage />} />} />
            <Route path="/admin" element={<ProtectedRoute element={<AdminPage />} />} />
            <Route path="/anonyme" element={<AnonymePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
        </Routes>
    );
};

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Main>
                <ModalContainer />
                <TopBar />
                <RoutesBlock />
            </Main>
        </BrowserRouter>
    );
};

export default App;
