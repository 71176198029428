// LoginForm.js
import React, { useState } from 'react';
import { Navigate } from 'react-router';
import configStore from '../stores/configStore';
import { observer } from 'mobx-react-lite';
import TelegramLoginButton from '../components/auth/TelegramLoginButton';
import GoogleAuthButton from '../components/auth/GoogleAuthButton';
import { Alert, Paper, Snackbar } from '@mui/material';
import { TextField, Button, Typography, Container, Box } from '@mui/material';

const LoginForm = observer(() => {
    const { login, externLogin, isAuthenticated } = configStore;
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [open, setOpen] = useState<boolean>(false);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!(await login(credentials))) setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const telegramAuth = async (user: Record<string, any>) => {
        if (!(await externLogin(user, 'telegram'))) setOpen(true);
    };

    const googleAuth = async (resp: Record<string, any>) => {
        if (!(await externLogin(resp, 'google'))) setOpen(true);
    };

    const showEmailLoginForm = window.location.hostname.includes('localhost');

    return isAuthenticated ? (
        <Navigate to="/" />
    ) : (
        <>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                    Login failed!
                </Alert>
            </Snackbar>
            <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
                <Paper sx={{ p: 2, mt: 2 }} elevation={3}>
                    <Typography>Вход через Google</Typography>
                    <GoogleAuthButton
                        dataOnauth={(resp: Record<string, any>) => googleAuth(resp)}
                    />
                </Paper>
                <Paper sx={{ p: 2, mt: 2 }} elevation={3}>
                    <Typography>Вход через Telegram</Typography>
                    <TelegramLoginButton
                        dataOnauth={(user: Record<string, any>) => telegramAuth(user)}
                    />
                </Paper>
                {showEmailLoginForm && (
                    <Paper sx={{ p: 2, mt: 2 }} elevation={3}>
                        <Typography>Вход по паролю</Typography>
                        <form onSubmit={handleSubmit}>
                            <Box mt={2}>
                                <TextField
                                    label="Имя пользователя"
                                    type="text"
                                    name="email"
                                    value={credentials.email}
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                />
                            </Box>
                            <Box mt={2}>
                                <TextField
                                    label="Пароль"
                                    type="password"
                                    name="password"
                                    value={credentials.password}
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                />
                            </Box>
                            <Box mt={2}>
                                <Button type="submit" variant="contained" color="primary">
                                    Войти
                                </Button>
                            </Box>
                        </form>
                    </Paper>
                )}
            </Container>
        </>
    );
});

export default LoginForm;
