import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Typography from '@mui/material/Typography';
import { Task, TaskStatusDescr, arrTaskStatus } from '../../stores/dataTypes';
import dataStore from '../../stores/dataStore';
import modalStore from '../../stores/modalStore';

interface TaskStatusPopupMenuProps {
    task: Task;
    selected: boolean;
}

export default function TaskSatusPopupMenu({ task, selected }: TaskStatusPopupMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const curStatusObj = arrTaskStatus.find(st => st.status === task.status);

    const setStatus = (item: TaskStatusDescr) => {
        handleClose();
        if (!task) return;
        modalStore.showConfirm({
            title: item.action,
            text: `${item.action} задачу ${task.name}?`,
            onConfirm: async () => {
                await dataStore.setTaskStatus(task.task_guid, item.status);
                await dataStore.getTasks(task.task_guid);
            }
        });
    };

    return (
        <>
            <IconButton
                size="small"
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                {curStatusObj && (
                    <curStatusObj.icon sx={{ color: selected ? curStatusObj.color : 'gray' }} />
                )}
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {arrTaskStatus.map(
                    st =>
                        curStatusObj?.status !== st.status && (
                            <MenuItem onClick={() => setStatus(st)}>
                                <IconButton>
                                    <st.icon sx={{ color: st.color }} />
                                </IconButton>
                                <Typography variant="inherit">{st.label}</Typography>
                            </MenuItem>
                        )
                )}
            </Menu>
        </>
    );
}
