import { Breakpoint } from '@mui/material';
import { action, makeObservable, observable } from 'mobx';

export type DialogDescr = {
    editorName: string;
    children: React.ReactElement;
    onResult: any;
    data?: any;
    style?: any;
    maxWidth?: Breakpoint;
};

export type ConfirmDescr = {
    title: string;
    text: string;
    onConfirm: any;
};

class ModalStore {
    private blockShow: boolean;

    @observable dialog: boolean;
    @observable dialogDescr: DialogDescr | undefined;
    @observable data: any;

    @observable confirm: boolean;
    @observable confirmDescr: any;

    constructor() {
        this.dialog = false;
        this.dialogDescr = undefined;
        this.data = {};

        this.confirm = false;
        this.confirmDescr = undefined;

        this.blockShow = false;

        makeObservable(this);
    }

    @action showModal = (dialogDescr: DialogDescr) => {
        if (this.blockShow) return;

        this.dialog = true;
        this.dialogDescr = dialogDescr;
        this.data = dialogDescr.data ?? {};
    };

    @action hideModal = () => {
        this.dialog = false;
        this.dialogDescr = undefined;
        this.data = {};
        this.blockShow = true;
        setTimeout(() => {
            this.blockShow = false;
        }, 500);
    };

    @action showConfirm = (confirmDescr: ConfirmDescr) => {
        if (this.blockShow) return;

        this.confirm = true;
        this.confirmDescr = confirmDescr;
    };

    @action hideConfirm = () => {
        this.confirm = false;
        this.confirmDescr = undefined;
        this.blockShow = true;
        setTimeout(() => {
            this.blockShow = false;
        }, 500);
    };

    @action setData = (data: any) => {
        this.data = data;
    };
}

export default new ModalStore();
