import React from 'react';
import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import dataStore from '../stores/dataStore';
import { Grid } from '@mui/material';
import TaskGroupList from '../components/TaskGroupList';
import TaskList from '../components/TaskList';
import { useSize } from '../hooks/useSize';

const HomePage: React.FC = observer(() => {
    // const { search } = useLocation();
    // const queryParams = new URLSearchParams(search);
    // const group = queryParams.get('group') || 'defaultGroup';
    // const taskgroup = queryParams.get('taskgroup') || 'defaultTaskGroup';
    // const page = queryParams.get('page') || '1';

    useEffect(() => {
        dataStore.getWorkGroups();
    }, []);

    const size = useSize();
    const showGroupList = size !== 'xs';

    return (
        <Grid container spacing={2} style={{ height: 'calc(100vh - 64px)' }}>
            {showGroupList && (
                <Grid item xs={3} style={{ height: '100%' }}>
                    <TaskGroupList />
                </Grid>
            )}
            <Grid item xs={showGroupList ? 9 : 12} style={{ height: '100%' }}>
                <TaskList />
            </Grid>
        </Grid>
    );
});

export default HomePage;
