import React from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import dataStore from '../../stores/dataStore';
import modalStore from '../../stores/modalStore';
import TaskEdit from '../../dialogs/taskedit';
import TaskRow from './TaskRow';
import TaskGroupListSelect from './../TaskGroupListSelect';
import TaskListFilterMenu from './TaskListFilterMenu';
import { useSize } from '../../hooks/useSize';

const TaskList = observer(() => {
    const addTask = () => {
        modalStore.showModal({
            editorName: 'Добавить задачу',
            children: <TaskEdit />,
            onResult: async (data: any) => {
                if (data?.name) {
                    const task = await dataStore.addTask(data);
                    await dataStore.getTasks(task.task_guid);
                }
            }
        });
    };

    const size = useSize();

    return (
        <Grid container direction="column" style={{ height: '100%', flexWrap: 'nowrap' }}>
            {size === 'xs' && (
                <Grid item>
                    <TaskGroupListSelect />
                </Grid>
            )}
            <Grid
                item
                style={{
                    flexGrow: 1,
                    overflow: 'auto'
                }}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '64px' }}></TableCell>
                            <TableCell>Задачи</TableCell>
                            <TableCell sx={{ width: '100px' }}>
                                <IconButton
                                    size="small"
                                    disableFocusRipple={true}
                                    onClick={() => addTask()}
                                >
                                    <AddIcon color="primary" />
                                </IconButton>
                                <TaskListFilterMenu />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataStore.tasks.map(task => (
                            <TaskRow key={task.task_guid} task={task} />
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
});

export default TaskList;
