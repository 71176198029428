import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Task } from '../../stores/dataTypes';
import dataStore from '../../stores/dataStore';
import TaskToolbar from './TaskToolbar';

interface TaskStatusProps {
    status: string;
}
const TaskStatus = ({ status }: TaskStatusProps) => {
    let text = 'Завершена',
        color = 'darkgray';
    if (status === 'A') {
        text = 'Активна';
        color = 'darkgreen';
    }
    return <Typography style={{ color }}>{text}</Typography>;
};

interface TaskRowProps {
    task: Task;
}

const TaskRow = observer(({ task }: TaskRowProps) => {
    const [open, setOpen] = useState(false);

    const clickHandler = (guid: string) => {
        dataStore.assignActiveTaskGuid(guid);
    };

    const fontColor = task.status === 'F' ? 'darkgray' : 'black';
    const selected = task.task_guid === dataStore.activeTaskGuid;

    return (
        <>
            <TableRow
                key={task.task_guid}
                selected={selected}
                onClick={() => clickHandler(task.task_guid)}
            >
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell style={{ color: fontColor }}>{task.name}</TableCell>
                <TableCell>{<TaskToolbar task={task} selected={selected} />}</TableCell>
            </TableRow>
            {open && (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={'auto'}>
                                <Typography>{'#' + task.task_number}</Typography>
                            </Grid>
                            <Grid item xs={true}>
                                <TaskStatus status={task.status} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        whiteSpace: 'pre-line',
                                        maxHeight: 200,
                                        overflowY: 'auto'
                                    }}
                                >
                                    {task.description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
});

export default TaskRow;
