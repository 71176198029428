import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { TaskStatus, arrTaskStatus } from '../../stores/dataTypes';
import dataStore from '../../stores/dataStore';
import Check from '@mui/icons-material/Check';

export default function TaskListFilterMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const switchStatus = (st: TaskStatus) => {
        dataStore.switchFilterStatus(st);
        handleClose();
    };

    return (
        <>
            <IconButton
                size="small"
                aria-label="more"
                id="filter-button"
                aria-controls={open ? 'filter-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <FilterAltIcon />
            </IconButton>
            <Menu
                id="filter-menu"
                MenuListProps={{
                    'aria-labelledby': 'filter-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {arrTaskStatus.map(st => (
                    <MenuItem onClick={() => switchStatus(st.status)}>
                        {dataStore.filterStatus[st.status] && (
                            <ListItemIcon>
                                <Check />
                            </ListItemIcon>
                        )}
                        <ListItemText inset={!dataStore.filterStatus[st.status]}>
                            {st.multiLabel}
                        </ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
