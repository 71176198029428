import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

interface GoogleLoginButtonProps {
    dataOnauth: any;
}

const GoogleAuthButton = ({ dataOnauth }: GoogleLoginButtonProps) => {
    const handleSuccess = (response: any) => {
        dataOnauth(response);
    };

    const handleError = () => {
        console.error('GoogleLogin error');
    };

    return (
        <div style={{ margin: '0 auto', width: '220px' }}>
            <GoogleOAuthProvider clientId="691584252094-7fk5c18b93lcp2k53jv3sq80qt0rr6nv.apps.googleusercontent.com">
                <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
            </GoogleOAuthProvider>
        </div>
    );
};

export default GoogleAuthButton;
