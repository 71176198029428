import React from 'react';
import { Task } from '../../stores/dataTypes';
import TaskPopupMenu from './TaskPopupMenu';
import TaskSatusPopupMenu from './TaskStatusPopupMenu';

interface TaskToolbarProps {
    task: Task;
    selected: boolean;
}

const TaskToolbar = ({ task, selected }: TaskToolbarProps) => {
    return (
        <>
            <TaskSatusPopupMenu task={task} selected={selected} />
            <TaskPopupMenu task={task} />
        </>
    );
};

export default TaskToolbar;
