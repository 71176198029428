import { backend } from './config';
import configStore from './stores/configStore';

export const apiFetch = async (
    url: string,
    method: string = 'POST',
    data?: Object,
    headers: Record<string, any> = {},
    resType: string = 'json'
) => {
    try {
        let body;
        if (method !== 'GET') {
            body = typeof data === 'object' ? JSON.stringify(data) : data;
            headers['Content-Type'] = 'application/json';
        }

        headers.Accept = 'application/json';
        if (configStore.accessToken) {
            headers.authorization = `bearer ${configStore.accessToken}`;
        }

        const fullUrl = `${backend.url}/${url}`;

        const response = await fetch(fullUrl, {
            method,
            body,
            headers
        });

        if (!response.ok) {
            const contType = (response.headers.get('Content-Type') || '').split(';');

            const res =
                contType[0] === 'application/json'
                    ? await response.json()
                    : { message: await response.text() };

            const apiError = {
                status: response.status,
                statusText: response.statusText,
                url: response.url,
                method,
                payload: body ? JSON.parse(body) : undefined,
                responseMessage: res,
                timestamp: Date.now()
            };

            console.error(apiError);

            if (apiError.status === 401) {
                configStore.logout();
            }

            throw new Error(apiError.responseMessage);
        } else {
            switch (resType) {
                case 'json':
                    return await response.json();
                case 'blob':
                    return await response.blob();
                case 'text':
                    return await response.text();
                default:
                    return await response.text();
            }
        }
    } catch (err) {
        console.log(err);
    }
};
