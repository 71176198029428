import React from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import dataStore from '../../stores/dataStore';
import modalStore from '../../stores/modalStore';
import TaskGroupEdit from '../../dialogs/taskgroupedit';

import TaskGroupRow from './TaskGroupRow';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const TaskGroupList = observer(() => {
    const addTaskGroup = () => {
        modalStore.showModal({
            editorName: 'Добавить список',
            children: <TaskGroupEdit />,
            onResult: async (data: any) => {
                if (data?.name) {
                    const taskGroup = await dataStore.addTaskGroup(data.name);
                    await dataStore.getTaskGroups(taskGroup.task_group_guid);
                }
            }
        });
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Grid container style={{ height: '100%', flexWrap: 'nowrap' }}>
                <Grid
                    item
                    style={{
                        flexGrow: 1,
                        overflow: 'auto'
                    }}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Списки</TableCell>
                                <TableCell>
                                    <IconButton size="small" onClick={() => addTaskGroup()}>
                                        <AddIcon color="primary" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataStore.taskGroups.map(gr => (
                                <TaskGroupRow key={gr.task_group_guid} taskGroup={gr} />
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </DndProvider>
    );
});

export default TaskGroupList;
