import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import modalStore from '../stores/modalStore';

const ConfirmationDialog = () => {
    const { title, text, onClose, onConfirm } = modalStore.confirmDescr;

    const handleClose = (result: boolean) => {
        if (result) {
            onConfirm && onConfirm();
        } else {
            onClose && onClose();
        }
        modalStore.hideConfirm();
    };

    return (
        <Dialog open onClose={() => handleClose(false)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    Нет
                </Button>
                <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                    Да
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
