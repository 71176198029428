import React, { FC, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import modalStore from '../stores/modalStore';

import {
    FormControl,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button
} from '@mui/material';

const SimpleDialog: FC = observer(() => {
    const { editorName, children, onResult, style, maxWidth } = modalStore.dialogDescr ?? {};
    const okButtonRef = useRef<HTMLButtonElement>(null);

    const handleClose = (result: boolean) => {
        if (result) {
            onResult && onResult(modalStore.data);
        }
        modalStore.hideModal();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        modalStore.setData({
            ...modalStore.data,
            [event.target.name]: event.target.value
        });
    };

    useEffect(() => {
        const handleKeyPress = (event: any) => {
            if (event.key === 'Enter') {
                if (event.target.tagName !== 'TEXTAREA') {
                    okButtonRef.current?.click();
                }
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <Dialog
            open
            fullWidth
            style={style}
            maxWidth={maxWidth ?? 'md'}
            onClose={() => handleClose(false)}
        >
            <DialogTitle>{editorName}</DialogTitle>
            <DialogContent id="dialogcontent">
                <FormControl onChange={handleChange} sx={{ width: '100%' }}>
                    {children}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button key="cancel" onClick={() => handleClose(false)} color="primary">
                    Отмена
                </Button>
                <Button
                    key="ok"
                    ref={okButtonRef}
                    onClick={() => handleClose(true)}
                    color="primary"
                >
                    ОК
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default SimpleDialog;
