import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import dataStore from '../../stores/dataStore';
import modalStore from '../../stores/modalStore';
import TaskEdit from '../../dialogs/taskgroupedit';
import Typography from '@mui/material/Typography';
import { Task } from '../../stores/dataTypes';

interface TaskPopupMenuProps {
    task: Task;
}

export default function TaskPopupMenu({ task }: TaskPopupMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const editTask = () => {
        handleClose();
        if (!task) return;
        modalStore.showModal({
            editorName: 'Изменить задачу',
            children: <TaskEdit />,
            data: task,
            onResult: async (data: any) => {
                if (data?.name) {
                    await dataStore.editTask(data);
                    await dataStore.getTasks(task.task_guid);
                }
            }
        });
    };

    const delTask = () => {
        handleClose();
        if (!task) return;
        modalStore.showConfirm({
            title: 'Удаление',
            text: `Удалить задачу ${task.name}?`,
            onConfirm: async () => {
                await dataStore.delTask(task.task_guid);
                await dataStore.getTasks();
            }
        });
    };

    return (
        <>
            <IconButton
                size="small"
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => editTask()}>
                    <IconButton>
                        <EditIcon />
                    </IconButton>
                    <Typography variant="inherit">Изменить</Typography>
                </MenuItem>
                <MenuItem onClick={() => delTask()}>
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                    <Typography variant="inherit">Удалить</Typography>
                </MenuItem>
            </Menu>
        </>
    );
}
