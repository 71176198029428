import React, { FC } from 'react';
import modalStore from '../stores/modalStore';
import { observer } from 'mobx-react-lite';
import SimpleDialog from './SimpleDialog';
import ConfirmationDialog from './ConfirmationDialog';

const ModalContainer: FC = observer(() => {
    return (
        <>
            {modalStore.dialog && <SimpleDialog />}
            {modalStore.confirm && <ConfirmationDialog />}
        </>
    );
});

export default ModalContainer;
