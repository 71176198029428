import * as React from 'react';
import { observer } from 'mobx-react-lite';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { SelectChangeEvent } from '@mui/material/Select';

import dataStore from '../stores/dataStore';
import { content, t } from '../translate';

const MAIN_LIST_NAME = '%MAIN%';

const TaskGroupListSelect = observer(() => {
    const handleChange = (event: SelectChangeEvent) => {
        dataStore.assignActiveTaskGroupGuid(event.target.value);
    };

    return (
        <FormControl sx={{ mt: 2, ml: 2, mb: 1, pr: 4 }} fullWidth size="small">
            <InputLabel id="TaskGroupListSelectLabel">Список</InputLabel>
            <Select
                labelId="TaskGroupListSelectLabel"
                value={dataStore.activeTaskGroupGuid ?? ''}
                onChange={handleChange}
                label="Список"
                fullWidth
            >
                {dataStore.taskGroups.map(taskGroup => (
                    <MenuItem key={taskGroup.task_group_guid} value={taskGroup.task_group_guid}>
                        {taskGroup.name === MAIN_LIST_NAME
                            ? t(content.system.defaultTaskGroupName)
                            : taskGroup.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});

export default TaskGroupListSelect;
