import { observable, action, makeObservable } from 'mobx';
import { apiFetch } from '../utils';

type Credentials = { email: string; password: string };
export type Lang = 'ru' | 'en';

class ConfigStore {
    accessToken: string | undefined;
    isAuthenticated: boolean;
    anonymeSession: boolean;
    lang: Lang;

    constructor() {
        this.accessToken = localStorage.getItem('accessToken') || undefined;
        this.isAuthenticated = !!this.accessToken;
        this.anonymeSession = this.isAuthenticated && !!localStorage.getItem('anonymeSession');
        this.lang = (localStorage.getItem('lang') as Lang) || 'ru';

        makeObservable(this, {
            accessToken: observable,
            isAuthenticated: observable,
            lang: observable,

            login: action,
            logout: action
        });
    }

    login = async (credentials: Credentials) => {
        const resp = await apiFetch('user/login', 'POST', credentials);
        if (resp?.token) {
            this.isAuthenticated = true;
            this.accessToken = resp.token;
            localStorage.setItem('accessToken', resp.token);
            localStorage.removeItem('anonymeSession');
            return true;
        }
    };

    externLogin = async (user: Record<string, any>, system: string) => {
        const resp = await apiFetch(`user/${system}-login`, 'POST', user);
        if (resp?.token) {
            this.isAuthenticated = true;
            this.anonymeSession = !!resp.anonymeSession;
            this.accessToken = resp.token;
            localStorage.setItem('accessToken', resp.token);
            if (this.anonymeSession) {
                localStorage.setItem('anonymeSession', '1');
            } else {
                localStorage.removeItem('anonymeSession');
            }
            return true;
        }
    };

    logout = () => {
        this.isAuthenticated && apiFetch('user/logout', 'POST', {});
        this.isAuthenticated = false;
        this.anonymeSession = false;
        this.accessToken = undefined;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('anonymeSession');
    };
}

export default new ConfigStore();
