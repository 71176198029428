import React from 'react';
import { observer } from 'mobx-react-lite';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { TaskGroup } from '../../stores/dataTypes';
import dataStore from '../../stores/dataStore';

import { content, t } from '../../translate';
import TaskGroupListPopupMenu from './TaskGroupListPopupMenu';

import { useDrag, useDrop } from 'react-dnd';

const MAIN_LIST_NAME = '%MAIN%';

interface TaskGroupRowProps {
    taskGroup: TaskGroup;
}

const TaskGroupRow = observer(({ taskGroup }: TaskGroupRowProps) => {
    const [, drag] = useDrag({
        type: 'TaskGroup',
        item: { type: 'TaskGroup', taskGroup },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    });

    const [, drop] = useDrop({
        accept: 'TaskGroup',
        hover(item, monitor) {
            const source: TaskGroup = (item as Record<string, any>).taskGroup as TaskGroup;
            if (source !== taskGroup) source && dataStore.reorderTaskGroups(source, taskGroup);
        },
        drop(item, monitor) {
            const source: TaskGroup = (item as Record<string, any>).taskGroup as TaskGroup;
            source && dataStore.saveNewTaskGroupOrder(source);
        }
    });

    const selected = taskGroup.task_group_guid === dataStore.activeTaskGroupGuid;

    const clickHandler = (guid: string) => {
        dataStore.assignActiveTaskGroupGuid(guid);
    };

    return (
        <TableRow
            ref={node => drag(drop(node))}
            selected={selected}
            onClick={() => clickHandler(taskGroup.task_group_guid)}
        >
            <TableCell>
                {taskGroup.name === MAIN_LIST_NAME
                    ? t(content.system.defaultTaskGroupName)
                    : taskGroup.name}
            </TableCell>
            <TableCell sx={{ width: '64px' }}>
                {selected ? <TaskGroupListPopupMenu /> : <div style={{ height: '34px' }}></div>}
            </TableCell>
        </TableRow>
    );
});

export default TaskGroupRow;
