import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import modalStore from '../stores/modalStore';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const TaskEdit = observer(() => {
    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    return (
        <>
            <Box mt={2}>
                <TextField
                    inputRef={ref}
                    name="name"
                    label="Задача"
                    value={modalStore.data.name ?? ''}
                    placeholder="Введите новую задачу"
                    autoFocus={true}
                    required
                    fullWidth
                />
            </Box>
            <Box mt={2}>
                <TextField
                    name="description"
                    label="Описание"
                    value={modalStore.data.description ?? ''}
                    multiline
                    rows={4}
                    fullWidth
                />
            </Box>
        </>
    );
});

export default TaskEdit;
