import { makeObservable, observable, action } from 'mobx';
import { apiFetch } from '../utils';
import { Group, TaskGroup, Task } from './dataTypes';
import { TaskStatus, FilterStatus } from './dataTypes';
class dataStore {
    workGroups: Group[] = [];
    taskGroups: TaskGroup[] = [];
    tasks: Task[] = [];
    activeWorkGroupGuid?: string;
    activeTaskGroupGuid?: string;
    activeTaskGuid?: string;
    filterStatus: FilterStatus = { W: true, A: true, F: false, C: false };

    constructor() {
        makeObservable(this, {
            workGroups: observable,
            taskGroups: observable,
            tasks: observable,
            activeWorkGroupGuid: observable,
            activeTaskGroupGuid: observable,
            activeTaskGuid: observable,
            filterStatus: observable,

            assignTaskGroups: action,
            assignActiveTaskGroupGuid: action,
            assignWorkGroups: action,
            assignActiveWorkGroupGuid: action,
            assignTasks: action,
            assignActiveTaskGuid: action,
            reorderTaskGroups: action,
            switchFilterStatus: action
        });
    }

    switchFilterStatus(st: TaskStatus) {
        this.filterStatus[st] = !this.filterStatus[st];
        this.getTasks(this.activeTaskGuid);
    }

    async getWorkGroups() {
        const resp = await apiFetch('group/list', 'POST');
        if (resp) {
            this.assignWorkGroups(resp);
            if (resp.length === 1) {
                this.assignActiveWorkGroupGuid(resp[0].group_guid);
            } else {
                const workGroup = resp.find((g: Group) => g.is_personal);
                if (workGroup) {
                    this.assignActiveWorkGroupGuid(workGroup.group_guid);
                }
            }
        }
    }

    async getTaskGroups(activeGuid?: string) {
        let resp = await apiFetch('taskgroup/list', 'POST', {
            group_guid: this.activeWorkGroupGuid
        });
        if (resp) {
            this.assignTaskGroups(resp);
            if (activeGuid) {
                await this.assignActiveTaskGroupGuid(activeGuid);
            } else if (resp.length === 1) {
                await this.assignActiveTaskGroupGuid(resp[0].task_group_guid);
            } else {
                const taskGroup = resp.find((g: TaskGroup) => g.is_default);
                if (taskGroup) {
                    await this.assignActiveTaskGroupGuid(taskGroup.task_group_guid);
                }
            }
        }
    }

    async getTasks(activeGuid?: string) {
        const resp = await apiFetch('task/list', 'POST', {
            task_group_guid: this.activeTaskGroupGuid,
            status: Object.entries(this.filterStatus)
                .filter(e => e[1])
                .map(e => e[0])
        });
        if (resp) {
            this.assignTasks(resp);
            if (activeGuid) {
                await this.assignActiveTaskGuid(activeGuid);
            } else if (resp.length > 0) {
                await this.assignActiveTaskGuid(resp[0].task_guid);
            } else {
                await this.assignActiveTaskGuid(undefined);
            }
        }
    }

    async addTask(task: Task) {
        const resp = await apiFetch('task/add', 'POST', {
            task_group_guid: this.activeTaskGroupGuid,
            name: task.name,
            description: task.description
        });
        return resp;
    }

    async editTask(task: Task) {
        const resp = await apiFetch('task/edit', 'POST', task);
        return resp;
    }

    async delTask(guid: string) {
        await apiFetch('task/del', 'POST', { guid });
    }

    async setTaskStatus(guid: string, status: TaskStatus) {
        await apiFetch('task/status', 'POST', { guid, status });
    }

    async addTaskGroup(name: string) {
        const resp = await apiFetch('taskgroup/add', 'POST', {
            group_guid: this.activeWorkGroupGuid,
            name
        });
        return resp;
    }

    async editTaskGroup(taskGroup: TaskGroup) {
        const resp = await apiFetch('taskgroup/edit', 'POST', taskGroup);
        return resp;
    }

    async delTaskGroup(guid: string) {
        await apiFetch('taskgroup/del', 'POST', { guid });
    }

    // action
    assignTaskGroups(taskGroups: TaskGroup[]) {
        this.taskGroups = taskGroups;
    }

    // action
    assignWorkGroups(workGroups: Group[]) {
        this.workGroups = workGroups;
    }

    // action
    assignTasks(tasks: Task[]) {
        this.tasks = tasks;
    }

    // action
    async assignActiveWorkGroupGuid(guid?: string) {
        this.activeWorkGroupGuid = guid;
        await this.getTaskGroups();
    }

    // action
    async assignActiveTaskGroupGuid(guid?: string) {
        this.activeTaskGroupGuid = guid;
        await this.getTasks();
    }

    // action
    async assignActiveTaskGuid(guid?: string) {
        this.activeTaskGuid = guid;
    }

    // action
    async reorderTaskGroups(source: TaskGroup, target: TaskGroup) {
        const targetOrder = target.sort_order;
        target.sort_order = source.sort_order;
        source.sort_order = targetOrder;
        this.assignTaskGroups(this.taskGroups.sort((a, b) => a.sort_order - b.sort_order));
    }

    async saveNewTaskGroupOrder(source: TaskGroup) {
        apiFetch('taskgroup/reorder', 'POST', source);
    }
}

export default new dataStore();
