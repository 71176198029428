import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import dataStore from '../../stores/dataStore';
import modalStore from '../../stores/modalStore';
import TaskGroupEdit from '../../dialogs/taskgroupedit';
import { Typography } from '@mui/material';

export default function TaskGroupListPopupMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const editTaskGroup = () => {
        handleClose();
        const data = dataStore.taskGroups.find(
            g => g.task_group_guid === dataStore.activeTaskGroupGuid
        );
        if (!data) return;
        modalStore.showModal({
            editorName: 'Изменить задачу',
            children: <TaskGroupEdit />,
            data,
            onResult: async (data: any) => {
                if (data?.name) {
                    const taskGroup = await dataStore.editTaskGroup(data);
                    await dataStore.getTaskGroups(taskGroup.task_group_guid);
                }
            }
        });
    };

    const delTaskGroup = () => {
        handleClose();
        const data = dataStore.taskGroups.find(
            g => g.task_group_guid === dataStore.activeTaskGroupGuid
        );
        if (!data) return;
        modalStore.showConfirm({
            title: 'Удаление',
            text: `Удалить список ${data.name}?`,
            onConfirm: async () => {
                await dataStore.delTaskGroup(data.task_group_guid);
                await dataStore.getTaskGroups();
            }
        });
    };

    return (
        <>
            <IconButton
                size="small"
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => editTaskGroup()}>
                    <IconButton>
                        <EditIcon />
                    </IconButton>
                    <Typography variant="inherit">Изменить</Typography>
                </MenuItem>
                <MenuItem onClick={() => delTaskGroup()}>
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                    <Typography variant="inherit">Удалить</Typography>
                </MenuItem>
            </Menu>
        </>
    );
}
