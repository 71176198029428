import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';

import configStore from '../stores/configStore';
import WorkGroupSelect from './WorkGroupSelect';

const TopBar = () => {
    const navigate = useNavigate();

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Todation
                </Typography>
                {configStore.isAuthenticated && (
                    <>
                        <WorkGroupSelect />
                        <IconButton onClick={() => navigate('/logout')} style={{ color: 'white' }}>
                            <LogoutIcon />
                        </IconButton>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
