import React from 'react';
import { observer } from 'mobx-react-lite';

const AnonymePage = observer(() => (
    <div>
        <h2>Анонимный пользователь</h2>
    </div>
));

export default AnonymePage;
