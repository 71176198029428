import React, { FC, ReactNode } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

interface PropsType {
    children: ReactNode;
}

const Main: FC<PropsType> = (props: PropsType) => {
    return (
        <>
            <CssBaseline />
            <Container maxWidth="lg" style={{ padding: 0 }}>
                <Box sx={{ height: '100vh', overflow: 'hidden' }}>{props.children}</Box>
            </Container>
        </>
    );
};

export default Main;
